// import { useAuthStore } from '~/store/auth/auth'

export default defineNuxtRouteMiddleware((_to, _from) => {
  /* const authStore = useAuthStore()
   if (!authStore.token) {
    return navigateTo({ path: Routes.Login })
  } else {
    return navigateTo({ path: _to.path })
  } */
})
